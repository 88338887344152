<template>
  <v-sheet class="pa-5 pb-10" elevation="1">
    <h1 class="display-1">Planning</h1>
    <v-divider class="mt-3"></v-divider>
    <div class="mt-5 d-flex justify-space-between align-center">
      <div style="width: 25%">
        <v-select
          v-model="van"
          :items="vanItems"
          item-text="brandModel"
          item-value="id"
          return-object
          outlined
          dense
          hide-details
        >
        </v-select>
      </div>
      <v-toolbar-title v-if="$refs.calendar">
        <span>{{ $refs.calendar.title }}</span> <br>
        <span>{{ `Week# ${weekNumber}`}}</span>
      </v-toolbar-title>
      <div>
        <v-btn
          text
          small
          outlined
          class="mr-2"
          color="grey darken-2"
          @click.stop="prev"
        >
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn
          text
          small
          outlined
          color="grey darken-2"
          class="mr-2"
          @click.stop="next"
        >
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
      </div>
    </div>
    <v-sheet style="height: 62vh" class="mt-5">
      <v-calendar
        ref="calendar"
        v-model="focus"
        :locale="lang"
        :events="tripsArray"
        :interval-format="intervalFormat"
        :short-weekdays="false"
        color="primary"
        :type="calendarType"
      >
        <template v-slot:event="{ event }">
          <div
            @click="openOrderDialog(event.details)"
            class="calendar-event fill-height fill-width d-flex align-center justify-center black--text"
          >
            <div>
              <div class="my-0 subtitle-2 text-center" v-if="event.name === 'main'">
                <p class="my-0 subtitle-2 text-center">
                  {{ event.details.customerName }}
                </p>
                <!-- <p
                  class="my-0 subtitle-2 text-center"
                  style="overflow-wrap: break-word"
                >
                  {{
                    event.details.destination
                  }}
                </p> -->
              </div>
              <!-- <p class="my-0 subtitle-2 text-center" v-else>{{ event.start.split(' ')[1] }} - {{ event.end.split(' ')[1] }}</p> -->
              <p class="my-0 subtitle-2 text-center" v-else>{{ event.name }}</p>
            </div>
          </div>
        </template>
      </v-calendar>
    </v-sheet>

    <!-- Dialog -->
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ selectedOrder.orderId }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="6" class="subtitle-2">Customer</v-col>
            <v-col cols="6">{{ selectedOrder.customerName }}</v-col>
            <v-col cols="6" class="subtitle-2">Driver</v-col>
            <v-col cols="6">{{ selectedOrder.driverName }}</v-col>
            <v-col cols="6" class="subtitle-2">Source</v-col>
            <v-col cols="6">{{ selectedOrder.source }}</v-col>
            <v-col cols="6" class="subtitle-2">Destination</v-col>
            <v-col cols="6">{{ selectedOrder.destination }}</v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="closeOrderDialog"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>

import * as dayjs from 'dayjs'
var weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

export default {

  data: () => ({
    focus: "",
    calendarType: "week",
    lang: "fr",
    vanItems: [],
    van: "",
    tripsArray: [],
    dialog: false,
    selectedOrder: {},
    weekNumber: 0
  }),

  watch: {
    async van(val) {
      this.tripsArray = await this.$store.dispatch("order/getOrdersByVanId", { vanId: val.id })
      console.log(this.tripsArray)
    }
  },

  mounted() {
    this.$refs.calendar.checkChange()
    this.weekNumber = dayjs(this.$refs.calendar.start).week()
    this.$store.dispatch('van/getVans').then(vanArray => {
      this.vanItems = vanArray
    })
  },

  computed: {
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
  },

  methods: {

    openOrderDialog(payload) {
      this.selectedOrder = payload
      this.dialog = true
    },

    closeOrderDialog() {
      this.selectedOrder = {}
      this.dialog = false
    },

    intervalFormat(interval) {
      return interval.time;
    },

    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
  }
}
</script>

<style></style>
